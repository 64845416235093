import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = [ "hidden" ]

  connect() {
    this.hidden = false
  }

  show() {
    this.element.classList.remove(this.hiddenClass)
    this.hidden = false
  }

  hide() {
    this.element.classList.add(this.hiddenClass)
    this.hidden = true
  }

  toggle() {
    if (this.hidden) {
      this.show()
    } else {
      this.hide()
    }
  }
}